import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../containers/BUAP/Homepage/HomePage";
import Error from "../containers/BUAP/Error";
import PageNotFound from "../components/PageNotFound";
const ViewOldBuap = React.lazy(() =>
  import("../components/BUAP/ViewOldBuap.js")
);

const Routes = (props) => {
  const authRouter = (componentname) => {
    let component;
    switch (componentname) {
      case "teacou":
        component = React.lazy(() =>
          import("../containers/BUAP/Teacher/ClassList/ClassList")
        );
        break;
      case "advlis":
        component = React.lazy(() =>
          import("../containers/BUAP/Advisor/AdviseeList/AdviseeList")
        );
        break;
      case "finsta":
        component = React.lazy(() =>
          import("../containers/BUAP/Student/Financial/Financial")
        );
        break;
      case "addadv":
        component = React.lazy(() =>
          import("../containers/BUAP/Advertisements/Advertisements")
        );
        break;
      case "geninf":
        component = React.lazy(() =>
          import("../containers/BUAP/Student/GeneralInfo/GeneralInfo")
        );
        break;
      case "schedu":
        component = React.lazy(() =>
          import("../containers/BUAP/Student/Schedule/Schedule")
        );
        break;
      case "transc":
        component = React.lazy(() =>
          import("../containers/BUAP/Student/Transcript/Transcript")
        );
        break;
      case "profst":
        component = React.lazy(() =>
          import("../containers/BUAP/Student/POS/POS")
        );
        break;
      case "paradi":
        component = React.lazy(() =>
          import("../containers/BUAP/Student/Paradigm/Paradigm")
        );
        break;
      case "finexa":
        component = React.lazy(() =>
          import("../containers/BUAP/Student/FinalExam/FinalExam")
        );
        break;
      case "forpro":
        component = React.lazy(() => import("../containers/BUAP/Forms/Forms"));
        break;
      case "feedba":
        component = React.lazy(() =>
          import("../containers/BUAP/SharedPages/Feedback/Feedback")
        );
        break;
      case "updinf":
        component = React.lazy(() =>
          import(
            "../containers/BUAP/Student/UpdateStudentInfo/UpdateStudentInfo"
          )
        );
        break;
      case "seafs":
        component = React.lazy(() =>
          import("../components/BUAP/Admin/SearchForAStudent")
        );
        break;
      case "updcon":
        component = React.lazy(() =>
          import("../containers/BUAP/Admin/UpdateAdmin")
        );
        break;
      case "config":
        component = React.lazy(() =>
          import("../containers/BUAP/Admin/BUAPConfig")
        );
        break;
      case "mobmsg":
        component = React.lazy(() =>
          import("../components/BUAP/Admin/Notifications")
        );
        break;
      case "semcou":
        component = React.lazy(() =>
          import("../components/BUAP/Teacher/CourseSchedule")
        );
        break;
      case "sid":
        component = React.lazy(() =>
          import("../components/BUAP/Admin/SearchForId")
        );
        break;
      case "glma":
        component = React.lazy(() =>
          import(
            "../components/BUAP/Admin/GoogleMeetLinks/GoogleMeetLinksAdmin"
          )
        );
        break;
      case "signit":
        component = React.lazy(() =>
          import("../components/BUAP/Teacher/Signature")
        );
        break;
      default: {
        <p>Page not found</p>;
      }
    }
    return component;
  };

  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      {props.data.map((row) => {
        return row.externalLink ? (
          <Route
            key={row.autoid}
            path={"/" + row.component}
            render={(props) => <ViewOldBuap page={row.buapLink} />}
          />
        ) : (
          <Route
            key={row.autoid}
            path={"/" + row.component}
            component={authRouter(row.component)}
          />
        );
      })}
      <Route path="/error" exact component={Error} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
