import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CreditCardImage from "../../../../assets/images/creditcard.png";
import getSemester from "../../../../Functions/GetSemester";

import moment from "moment";
import Select from "react-select";
const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    borderStyle: "outset;",
    width: "365px",
    margin: "auto",
    marginTop: "10px",
  },
});

class PALPAY extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: this.props.min,
      options: [
        {
          value: this.props.min,
          label: `Minumum payment: ${this.props.min} ${this.props.currency}`,
        },
        {
          value: this.props.balance,
          label: `Total payment: ${this.props.balance} ${this.props.currency}`,
        },
      ],
    };
  }
  handleChange = (value) => {
    this.setState({
      amount: value.value,
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        <div>
          <form
            action="https://obuap.bethlehem.edu/bop/bop.php"
            method="post"
            target="_blank"
          >
            <input type="hidden" name="stName" value={this.props.stName} />
            <input type="hidden" name="stNo" value={this.props.stNo} />
            <input
              type="hidden"
              name="ccPay_term_digit"
              value={this.props.term}
            />
            <input
              type="hidden"
              name="ccPay_term"
              value={getSemester(this.props.term)}
            />
            <input type="hidden" name="ccPay_min" value={this.state.amount} />
            <input type="hidden" name="stFblock" value={0} />
            <input
              type="hidden"
              name="currentDegreeCode"
              value={this.props.studeg}
            />
            <input type="hidden" name="live" value="1" />
            <input
              type="hidden"
              name="timeStamp"
              value={moment().format("y-m-d-h-m-s")}
            />
            <input
              type="hidden"
              name="currency_note"
              value={this.props.currency}
            />

            <div
              style={{
                textAlign: "center",
              }}
            >
              <b>
                Select amount to pay: اختيار المبلغ للدفع
                <Select
                  value={this.state.options.filter(
                    (option) => option.value === this.state.amount
                  )}
                  options={this.state.options}
                  onChange={this.handleChange}
                />
              </b>
            </div>
            <button
              style={{
                backgroundColor: "#007bff",
                color: "white",
                padding: "12px",
                margin: "4px",
                width: "100%",
              }}
              type="submit"
            >
              Pay using Credit Card
            </button>
          </form>
        </div>

        <div>
          <img src={CreditCardImage} alt="CreditCardImage" />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(PALPAY);
